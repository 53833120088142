import React, { useState } from 'react';
import './CitySelector.css'; 
import logoPrincipal from '../assets/LOGO_PRINCAL.png'; 
import logoPreto from '../assets/LOGO_PRETO.png'; 
import logoVerde from '../assets/logo_2.png'; 
import logo from '../assets/logo.png'; 

function CitySelector({ onSelectCity }) {
  const [selectedCity, setSelectedCity] = useState('');

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleConfirm = () => {
    if (selectedCity) {
      console.log('Cidade selecionada:', selectedCity); 
      onSelectCity(selectedCity); 
    } else {
      alert('Por favor, selecione uma cidade.');
    }
  };

  return (
    <section className="hero-section">
      <div className="city-selector-container">
        <div className="city-selector-content">
          <img src={logoPreto} alt="Logo da Empresa" className="city-selector-logo" />
          <div className="city-selector-form">
            <h1>Escolha sua cidade</h1>
            <label htmlFor="city-dropdown" className="sr-only">Selecione uma cidade</label>
            <div className="dropdown-wrapper">
              <select
                id="city-dropdown"
                value={selectedCity}
                onChange={handleCityChange}
                className="city-selector-dropdown"
                aria-label="Selecione uma cidade"
              >
                <option value="">Selecione uma cidade</option>
                <option value="Conselheiro Lafaiete">Conselheiro Lafaiete</option>
                <option value="Congonhas">Congonhas</option>
              </select>
              <span className="dropdown-arrow"></span>
            </div>
            <button onClick={handleConfirm} className="btn-primary">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CitySelector;
