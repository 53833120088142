import React, { useState, useEffect } from 'react';
import './Header.css';
import { Link } from 'react-scroll';
import { FiMenu, FiX } from 'react-icons/fi';
import { FaWhatsapp, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { IoMdRocket } from 'react-icons/io';
import { useNavigate, useLocation } from 'react-router-dom';
import logoPrincipal from '../assets/LOGO_PRINCAL.png'; 
import logoPreto from '../assets/LOGO_PRETO.png'; 
import logoVerde from '../assets/logo_2.png'; 
import logo from '../assets/logo.png'; 

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Adicionado para rolar para o topo em mudanças de rota
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Identifica se estamos em uma página especial (não inicial)
  const isSpecialPage = location.pathname !== '/' && location.pathname !== '/home';

  // Manipula o clique nos links
  const handleLinkClick = () => {
    if (isSpecialPage) {
      navigate('/');
    } else {
      setIsMenuOpen(false);
    }
  };

  // Manipula o clique no logotipo
  const handleLogoClick = () => {
    if (location.pathname === '/' || location.pathname === '/home') {
      // Se já estiver na página inicial, rola para o topo
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Caso contrário, navega para a página inicial
      navigate('/', { replace: true });
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 100);
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className="header-container">
        <div className="header-top">
          <div className="left-icons">
            <FaWhatsapp /> <span>(31) 99845-8433</span>
          </div>
          <div className="header-message">
            <IoMdRocket size={18} className="rocket-icon" /> <span>Internet de alta qualidade</span>
          </div>
          <div className="right-icons">
            <FaEnvelope /> <span>contato@vitalinkcl.com</span>
            <FaInstagram className="instagram-icon" /> <span>vitalinkcl</span>
          </div>
        </div>

        <div className="header-main">
          <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
            <img src={logoPreto} alt="VitaLink" />
          </div>
          <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
            <Link
              to="home"
              smooth
              duration={500}
              spy={!isSpecialPage}
              activeClass="active"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Home
            </Link>
            <Link
              to="about"
              smooth
              duration={500}
              spy={!isSpecialPage}
              activeClass="active"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Quem Somos
            </Link>
            <Link
              to="plans"
              smooth
              duration={500}
              spy={!isSpecialPage}
              activeClass="active"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Planos
            </Link>
            <a
              href="https://www.speedtest.net/pt"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              Teste de Velocidade
            </a>
            <Link
              to="faq"
              smooth
              duration={500}
              spy={!isSpecialPage}
              activeClass="active"
              className="nav-link"
              onClick={handleLinkClick}
            >
              Dúvidas
            </Link>
            
            {/* Adiciona contatos e login no menu hamburguer */}
            <div className="mobile-contacts">
              <button
                className="nav-link mobile-contact-link"
                onClick={() => {
                  navigate('/contact');
                  setIsMenuOpen(false);
                }}
              >
                Contato
              </button>
              <button
                className="nav-link mobile-login-link"
                onClick={() => {
                  navigate('/login');
                  setIsMenuOpen(false);
                }}
              >
                Login
              </button>
            </div>
          </nav>
          <div className="header-buttons">
            <button className="contact-button" onClick={() => navigate('/contact')}>
              Contato
            </button>
            <button className="login-button" onClick={() => navigate('/login')}>
              Login
            </button>
          </div>
          <div className="menu-icon" onClick={toggleMenu}>
            {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
